import { RootState } from '@/store/types'
import { EnergyStatusGetter } from '@ecocoach/domain-store-modules/src/energyStatus/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { EnergyStatusViewModel } from '@ecocoach/shared-components/src/components/energyStatusView/models'
import { GetterTree } from 'vuex'
import { ToastModel } from './models'
import { AppGetter, AppState } from './types'

export const getters: GetterTree<AppState, RootState> = {
  [AppGetter.projectName](_, __, rootState): string {
    return rootState.energyStatus.projectName
  },
  [AppGetter.energyStatusViewModel](_, __, ___, rootGetters) {
    return {
      producers: rootGetters[`energyStatus/${EnergyStatusGetter.producers}`],
      consumers: rootGetters[`energyStatus/${EnergyStatusGetter.consumers}`],
      productionContributions: rootGetters[`energyStatus/${EnergyStatusGetter.productionContributions}`],
      consumptionContributions: rootGetters[`energyStatus/${EnergyStatusGetter.consumptionContributions}`],
      resolveIcon: rootGetters[`resource/${ResourceGetter.svgIcon}`],
    } as EnergyStatusViewModel
  },

  [AppGetter.isLoading](state: AppState): boolean {
    return state.isLoading
  },
  [AppGetter.selectedLanguage](state: AppState): string {
    return state.selectedLanguage
  },
  [AppGetter.toast]({ toast }): ToastModel | null {
    return toast
  },
}
