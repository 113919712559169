<template>
  <v-layout row align-center justify-space-between class="app-header">
    <v-flex shrink class="app-header-logo">
      <svg-icon class="logo-image" :icon="'eco.logo.grey'" :width="40" :height="40"/>
    </v-flex>
    <v-flex shrink class="app-header-separator"/>
    <v-flex shrink class="app-header-text">{{ projectName }}</v-flex>
    <v-flex grow></v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import SvgIcon from './SvgIcon.vue'

const App = namespace('app')

@Component({
  components: {
    SvgIcon,
  },
})
export default class AppHeader extends Vue {
  @App.Getter public projectName: string
}
</script>

<style lang="scss" scoped>
.app-header {
  background-color: #09162a;
  .app-header-back {
    width: 51px;
  }
  .app-header-back-button {
    margin: 0px;
    height: 35px;
    width: 35px
  }
  .app-header-separator {
    height: 15px;
    min-width: 1px;
    background-color: #7a8b9a;
    margin: 0px 5px;
  }
  .app-header-text {
    font-size: 16px;
    color: #7a8b9a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-header-menu {
    margin-right: 15px;
    .icon-button {
      margin: 0px;
    }
    .offline-icon {
      margin-left: 10px;
    }
  }
}

</style>

<style lang="css">
</style>