export class ToastModel {
  public static info(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'info'
    toast.message = message
    return toast
  }

  public static warning(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'warning'
    toast.message = message
    return toast
  }

  public static error(message: string): ToastModel {
    const toast = new ToastModel()
    toast.type = 'error'
    toast.message = message
    return toast
  }
  public get color(): string {
    switch (this.type) {
    case 'info': return '#1a3856' // dark blue
    case 'warning': return '#1a3856' // dark blue
    case 'error': return '#fa0f36' // red
    default: return ''
    }
  }
  public get timeout(): number {
    return 5000
  }

  public message: string

  private type: 'info' | 'warning' | 'error'
}